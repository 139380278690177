import React, { useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Button, Col, Row } from "react-bootstrap"
import { toast } from "react-toastify"

import UploadIcon from "../../../../images/upload-icon.svg"
import { signAuthMessage } from "../../../../web3/web3"
import { updateInfo } from "../../../../api/ido"

const InformationFormValidation = Yup.object().shape({
  image: Yup.string().required(),
  description: Yup.string().required(),
  userexplorer: Yup.string().nullable(true),
  whitepaper: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(.)*$/, "Invalid website url"),
  telegram: Yup.string().required(), //.matches(/^((http|https):\/\/)(t.me|telegram.me)\/(.)*$/, "Invalid telegram url"),
  twitter: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(twitter.com)\/(.)*$/, "Invalid twitter url"),
  discord: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(discord.com)\/(.)*$/, "Invalid discord url"),
  instagram: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(instagram.com)\/(.)*$/, "Invalid instagram url"),
  facebook: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(facebook.com)\/(.)*$/, "Invalid facebook url"),
  medium: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(medium.com)\/(.)*$/, "Invalid medium url"),
  github: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  linktree: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  youtube: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  tiktok: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  reddit: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  linkedin: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  adsvideo: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  referral_address: Yup.string().nullable(true),
  tags: Yup.string().nullable(true),
  kycurl: Yup.string().matches(/^((http|https):\/\/)(hedgepay.org|certik.com|analytixaudit.com|hashex.org|freshcoins.io|expelee.com|auditrate.tech|audits.quillhash.com|cyberscope.io|rugdoc.io|cognitos.io)\/(.)*$/, "We accept KYC url from HedgePay, Certik, Analytix Audit, HashEx, Freshcoins, expelee, Audit Rate Tech, Quill Audit, Cyberscope, RUGDOC, RugFreeCoins, ShellBoxes, InterFi, CFG Ninja, Cognitos"),
  auditurl: Yup.string().nullable(true).matches(/^((http|https):\/\/)(hedgepay.org|certik.com|analytixaudit.com|hashex.org|freshcoins.io|expelee.com|auditrate.tech|audits.quillhash.com|cyberscope.io|rugdoc.io|rugfreecoins.com|shellboxes.com|interfi.network|cfg.ninja|cognitos.io)\/(.)*$/, "We accept Audit url from HedgePay, Certik, Analytix Audit, HashEx, Freshcoins, expelee, Audit Rate Tech, Quill Audit, Cyberscope, RUGDOC, RugFreeCoins, ShellBoxes, InterFi, CFG Ninja, Cognitos"),
})

const InfoForm = ({ values, address, handleUpdateModal }) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema: InformationFormValidation,
  })

  const submit = async () => {
    const siganture = await signAuthMessage()
    if (siganture) {
      try {
        await updateInfo(address, formik.values, siganture)
        toast.success("Updated!")
      } catch {
        toast.error("Could not execute operation")
      }
    }
  }

  useEffect(()=> {
    console.log(formik.errors)
    console.log(formik.touched)
  },[formik.errors, formik.touched])

  const image = formik.values?.image
    ? URL.createObjectURL(formik.values?.image)
    : formik.values?.imagePath && formik.values?.imagePath.trim().length > 0
    ? formik.values?.imagePath
    : UploadIcon
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="d-flex align-items-start justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="hpaycreatehead me-2">Information</h3>
              <span className="popin-400-13 grey1-text">&#40;Edit &#41;</span>
            </div>

            <img
              onClick={handleUpdateModal}
              role="button"
              src={"/icons/Close.svg"}
              alt="close btn"
            />
          </div>
        </Col>
      </Row>
      <hr className="mb-3 hpaycreate2line" />

      <Row className="mt-5 mb-3 justify-content-center">
        <Col md="auto" className="mb-3 text-center">
          <input
            type="file"
            onChange={({ target: { name, files } }) =>
              formik.handleChange({ target: { name, value: files[0] } })
            }
            multiple={false}
            onBlur={formik.handleBlur}
            name="image"
            accept="image/*"
            id="upload"
            hidden
          />
          <label htmlFor="upload" className="mainupload_image mx-auto mb-0">
            <div className="upload-image">
              <img src={image} alt="upload" />
            </div>
            <span>
              Upload Image <br />
              200x200px
            </span>
          </label>
        </Col>

        <Col className="mb-3">
          <textarea
            placeholder="Project Description*"
            value={formik.values?.description}
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows="6"
            className="form-control form-hpay"
          />
          {formik.errors.description && formik.touched.description && (
            <small
              style={{ color: "red" }}
              children={formik.errors.description}
            />
          )}
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          <span className="connectwallettextpricinggold">Social</span>
        </Col>
      </Row>

      <Row md={6}>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-telegram"
              value={formik.values.telegram}
              name="telegram"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiustop"
              required
            />
            <label
              for="hpad-telegram"
              class="hpadcreateinputfield__label hpadcreateinputfield__label--required"
            >
              Telegram
            </label>
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/telegram.svg"}
              alt="website"
            />
            {formik.errors.telegram && formik.touched.telegram && (
              <small
                className="hpadrequiredred"
                children={formik.errors.telegram}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-twitter"
              value={formik.values.twitter}
              name="twitter"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              required
            />
            <label
              for="hpad-twitter"
              class="hpadcreateinputfield__label hpadcreateinputfield__label--required"
            >
              Twitter
            </label>
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/twitter.svg"}
              alt="twitter"
            />
            {formik.errors.twitter && formik.touched.twitter && (
              <small
                className="hpadrequiredred"
                children={formik.errors.twitter}
              />
            )}

            {formik.errors.twitter && formik.touched.twitter && (
              <small
                className="hpadrequiredred"
                children={formik.errors.twitter}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-intragram"
              value={formik.values.instagram}
              name="instagram"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="Instagram"
            />
            <img
              className="hpadcreateinputfield__image2"
              src={"/hpadcreateicon/intragram.svg"}
              alt="website"
            />
            {formik.errors.instagram && formik.touched.instagram && (
              <small
                className="hpadrequiredred"
                children={formik.errors.instagram}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-facebook"
              value={formik.values.facebook}
              name="facebook"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="Facebook"
            />
            <img
              className="hpadcreateinputfield__image2"
              src={"/hpadcreateicon/facebook.svg"}
              alt="facebook"
            />
            {formik.errors.facebook && formik.touched.facebook && (
              <small
                className="hpadrequiredred"
                children={formik.errors.facebook}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-github"
              value={formik.values.github}
              name="github"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="Github"
            />
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/github.svg"}
              alt="github"
            />
            {formik.errors.github && formik.touched.github && (
              <small
                className="hpadrequiredred"
                children={formik.errors.github}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-discord"
              value={formik.values.discord}
              name="discord"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="Discord"
            />
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/discord.svg"}
              alt="discord"
            />
            {formik.errors.discord && formik.touched.discord && (
              <small
                className="hpadrequiredred"
                children={formik.errors.discord}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-linktree"
              value={formik.values.linktree}
              name="linktree"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="Linktree"
            />
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/linktree.svg"}
              alt="discord"
            />
            {formik.errors.linktree && formik.touched.linktree && (
              <small
                className="hpadrequiredred"
                children={formik.errors.linktree}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-youtube"
              value={formik.values.YouTube}
              name="youtube"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="YouTube"
            />
            <img
              className="hpadcreateinputfield__image2"
              src={"/hpadcreateicon/adsvideo.svg"}
              alt="youtube"
            />
            {formik.errors.youtube && formik.touched.youtube && (
              <small
                className="hpadrequiredred"
                children={formik.errors.youtube}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-TikTok"
              value={formik.values.tiktok}
              name="TikTok"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="TikTok"
            />
            <img
              className="hpadcreateinputfield__image2"
              src={"/hpadcreateicon/adsvideo.svg"}
              alt="TikTok"
            />
            {formik.errors.tiktok && formik.touched.tiktok && (
              <small
                className="hpadrequiredred"
                children={formik.errors.tiktok}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-Reddit"
              value={formik.values.reddit}
              name="Reddit"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="Reddit"
            />
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/reddit.svg"}
              alt="Reddit"
            />
            {formik.errors.reddit && formik.touched.reddit && (
              <small
                className="hpadrequiredred"
                children={formik.errors.reddit}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-LinkedIn"
              value={formik.values.linkedin}
              name="LinkedIn"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4inputborderradiusbtm"
              placeholder="LinkedIn"
            />
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/LinkedIn.svg"}
              alt="LinkedIn"
            />
            {formik.errors.linkedin && formik.touched.linkedin && (
              <small
                className="hpadrequiredred"
                children={formik.errors.linkedin}
              />
            )}
          </fieldset>
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-Medium"
              value={formik.values.medium}
              name="Medium"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4inputborderradiusbtm"
              placeholder="Medium"
            />
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/medium.svg"}
              alt="Medium"
            />
            {formik.errors.medium && formik.touched.medium && (
              <small
                className="hpadrequiredred"
                children={formik.errors.medium}
              />
            )}
          </fieldset>
        </Col>
      </Row>

      <Row className="mt-4 mb-2">
        <Col>
          <span className="connectwallettextpricinggold">Main Info</span>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpadwebsite"
              value={formik.values.website}
              name="website"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiustop"
              required
            />
            <label
              for="hpadwebsite"
              class="hpadcreateinputfield__label hpadcreateinputfield__label--required"
            >
              Website
            </label>
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/website.svg"}
              alt="website"
            />
          </fieldset>
          {formik.errors.website && formik.touched.website && (
            <small
              className="hpadrequiredred"
              children={formik.errors.website}
            />
          )}
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-Explorer"
              value={formik.values.userexplorer}
              name="userexplorer"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiustop"
              required
            />
            <label
              for="hpad-Explorer"
              class="hpadcreateinputfield__label hpadcreateinputfield__label--required"
            >
              Explorer link
            </label>
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/explorerlink.svg"}
              alt="explorerlink"
            />
          </fieldset>
          {formik.errors.userexplorer && formik.touched.userexplorer && (
            <small
              className="hpadrequiredred"
              children={formik.errors.userexplorer}
            />
          )}
        </Col>
        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-whitepaper"
              value={formik.values.whitepaper}
              name="whitepaper"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              required
            />
            <label
              for="hpad-whitepaper"
              class="hpadcreateinputfield__label hpadcreateinputfield__label--required"
            >
              whitepaper URL
            </label>
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/whitepaper.svg"}
              alt="whitepaper"
            />
          </fieldset>
          {formik.errors.whitepaper && formik.touched.whitepaper && (
            <small
              className="hpadrequiredred"
              children={formik.errors.whitepaper}
            />
          )}
        </Col>

        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-Audit"
              value={formik.values.Audit}
              name="auditurl"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
              placeholder="Audit URL"
            />
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/audit.svg"}
              alt="audit"
            />
            {formik.errors.auditurl && formik.touched.auditurl && (
              <small
                className="hpadrequiredred"
                children={formik.errors.auditurl}
              />
            )}
          </fieldset>
        </Col>

        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-KYC"
              value={formik.values.kycurl}
              name="kycurl"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4inputborderradiusmed"
              placeholder="KYC URL"
            />
            <img
              className="hpadcreateinputfield__image"
              src={"/hpadcreateicon/KYC.svg"}
              alt="KYC"
            />
            {formik.errors.kycurl && formik.touched.kycurl && (
              <small
                className="hpadrequiredred"
                children={formik.errors.kycurl}
              />
            )}
          </fieldset>
        </Col>

        <Col md={6}>
          <fieldset className="hpadcreateinputfield">
            <input
              type="text"
              id="hpad-adsvideo"
              value={formik.values.adsvideo}
              name="adsvideo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4inputborderradiusbtm"
              placeholder="Ads Video URL"
            />
            <img
              className="hpadcreateinputfield__image2"
              src={"/hpadcreateicon/adsvideo.svg"}
              alt="adsvideo"
            />
            {formik.errors.adsvideo && formik.touched.adsvideo && (
              <small
                className="hpadrequiredred"
                children={formik.errors.adsvideo}
              />
            )}
          </fieldset>
        </Col>
      </Row>
      <Row className="w-100 mt-3 d-flex justify-content-center">
        <div className="">
            <div className="d-flex justify-content-center">

            
          <Button onClick={submit}>Update</Button>

          </div>
        </div>
      </Row>

      {/* <Row>
        <Col md={6} className="mb-3">
          <input
            type="text"
            value={formik.values?.website}
            name="website"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Website*"
            className="form-control form-hpay"
          />
          {formik.errors.website && formik.touched.website && (
            <small style={{ color: "red" }} children={formik.errors.website} />
          )}
        </Col>

        <Col md={6} className="mb-3">
          <input
            type="text"
            value={formik.values?.telegram}
            name="telegram"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Telegram*"
            className="form-control form-hpay"
          />
          {formik.errors.telegram && formik.touched.telegram && (
            <small style={{ color: "red" }} children={formik.errors.telegram} />
          )}
        </Col>

        <Col md={6} className="mb-3">
          <input
            type="text"
            value={formik.values?.twitter}
            name="twitter"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Twitter"
            className="form-control form-hpay"
          />
          {formik.errors.twitter && formik.touched.twitter && (
            <small style={{ color: "red" }} children={formik.errors.twitter} />
          )}
        </Col>

        <Col md={6} className="mb-3">
          <input
            type="text"
            value={formik.values?.discord}
            name="discord"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Discord"
            className="form-control form-hpay"
          />
          {formik.errors.discord && formik.touched.discord && (
            <small style={{ color: "red" }} children={formik.errors.discord} />
          )}
        </Col>

        <Col md={6} className="mb-3">
          <input
            type="text"
            value={formik.values?.instagram}
            name="instagram"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Instagram"
            className="form-control form-hpay"
          />
          {formik.errors.instagram && formik.touched.instagram && (
            <small
              style={{ color: "red" }}
              children={formik.errors.instagram}
            />
          )}
        </Col>
        <Col md={6} className="mb-3">
          <input
            type="text"
            value={formik.values?.facebook}
            name="facebook"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Facebook"
            className="form-control form-hpay"
          />
          {formik.errors.facebook && formik.touched.facebook && (
            <small style={{ color: "red" }} children={formik.errors.facebook} />
          )}
        </Col>
        <Col md={6} className="mb-3">
          <input
            type="text"
            value={formik.values?.medium}
            name="medium"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Medium"
            className="form-control form-hpay"
          />
          {formik.errors.medium && formik.touched.medium && (
            <small style={{ color: "red" }} children={formik.errors.medium} />
          )}
        </Col>
        <Col md={6} className="mb-3">
          <input
            type="text"
            value={formik.values?.github}
            name="github"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Github"
            className="form-control form-hpay"
          />
          {formik.errors.github && formik.touched.github && (
            <small style={{ color: "red" }} children={formik.errors.github} />
          )}
        </Col>

        <Col md={6} className="ms-auto">
          <Button onClick={submit} className="float-end">
            Update
          </Button>
        </Col>
      </Row> */}
    </>
  )
}

export default InfoForm
